export default class EnvService {
    public static _instance: EnvService | null = null;
    public static instance = (): EnvService => {
        if(EnvService._instance == null) {
            EnvService._instance = new EnvService();
        }

        return EnvService._instance;
    }

    public REACT_APP_MODE = (): string | undefined => {
        console.log(`process.env.REACT_APP_MODE: ${process.env.REACT_APP_MODE}`);
        return process.env.REACT_APP_MODE;
    }
}