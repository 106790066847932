import * as React from 'react';
import styles from './LinkCircle.module.scss';
import ILinkCircleProps from './ILinkCircleProps';
import ILinkCircleState from './IContainerState';
import Link from '../Link/LinkButton';

export default class Maintenance extends React.Component<ILinkCircleProps, ILinkCircleState> {
    constructor(props: ILinkCircleProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<ILinkCircleProps> => {
        return (<div className={styles.LinkCircle}>
            <div className={styles.LinkCircleCenter}>
                {this.props.children}
            </div>
            <div className={styles.LinkCircleCluster}>

                <div className={styles.LinkCircleClusterNode}>
                    <Link id="linkedin" href="https://linkedin.com/in/pascal-funke-57b7b6260" title="LinkedIn" />
                </div>
                <div className={styles.LinkCircleClusterNode}>
                    <Link id="xing" href="https://xing.com/profile/Pascal_Funke" title="XING" />
                </div>
                <div className={styles.LinkCircleClusterNode}>
                    <Link id="gitlab" href="https://gitlab.com/mr_5p4rk_" title="GitLab" />
                </div>
                <div className={styles.LinkCircleClusterNode}>
                    <Link id="github" href="https://github.com/mr-5p4rk" title="GitHub" />
                </div>
                <div className={styles.LinkCircleClusterNode}>
                    <Link id="discord" href="https://discordapp.com/users/232604009276375040" title="Discord" />
                </div>
                <div className={styles.LinkCircleClusterNode}>
                    <Link id="paypal" href="https://paypal.com/paypalme/funkep" title="PayPal" />
                </div>
            </div>
        </div>);
    }
}