import * as React from 'react';
import styles from './Live.module.scss';
import ILiveProps from './ILiveProps';
import ILiveState from './ILiveState';
import LinkCircle from '../LinkCircle/LinkCircle';
import Avatar from '../Avatar/Avatar';

export default class Live extends React.Component<ILiveProps, ILiveState> {
    constructor(props: ILiveProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<ILiveProps> => {
        return (<div className={styles.Live}>
            <div className={styles.LiveContent}>
                <LinkCircle>
                    <Avatar firstName='Pascal' lastName='Funke' nickName='mr_5p4rk_' subject='Hello there!' />
                </LinkCircle>
            </div>
        </div>);
    }
}