import * as React from 'react';
import styles from './App.module.scss';
import IAppProps from './IAppProps';
import { BrowserRouter as Router/*, Route, Routes*/ } from "react-router-dom";
import IAppState from './IAppState';
import EnvService from '../../services/EnvService';
import ComingSoon from '../ComingSoon/ComingSoon';
import Maintenance from '../Maintenance/Maintenance';
import Live from '../Live/Live';

export default class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props)
    this.state = {
      component_map: {
        'COMING_SOON': <ComingSoon />,
        'MAINTENANCE': <Maintenance />,
        'LIVE': <Live />
      }
    }
  }

  public componentDidMount = async (): Promise<void> => {
    console.log();
  }

  public render = (): React.ReactElement<IAppProps> => {
    const mode: string | undefined = EnvService.instance().REACT_APP_MODE();

    return (<div className={styles.App}>
      <Router>
        {(mode) ? this.state.component_map[mode] : <></>}
      </Router>
    </div>);
  }
}

