import * as React from 'react';
import styles from './Avatar.module.scss';
import IAvatarProps from './IAvatarProps';
import IAvatarState from './IAvatarState';
import Logo2 from '../../logo2.png';

export default class Avatar extends React.Component<IAvatarProps, IAvatarState> {
    constructor(props: IAvatarProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<IAvatarProps> => {
        const { firstName, lastName, nickName } = this.props;

        return (<div className={styles.Avatar}>
            <div className={styles.Image}>
                <img src={Logo2} alt="" />
            </div>
            {/*<div className={styles.Text}>
                < div className={styles.Header}>
            <div className={styles.FullName}>{`${firstName} ${lastName}`}</div>
            <div className={styles.NickName}>{`(${nickName})`}</div>
        </div>
            * <div className={styles.Footer}>
                <div className={styles.Subject}>{`${subject}`}</div>
            </div>
            </div >*/}
        </div>);
    }
}