import * as React from 'react';
import styles from './Link.module.scss';
import ILinkProps from './ILinkProps';
import ILinkState from './ILinkState';
import { BiLogoXing, BiLogoLinkedin, BiLogoGithub, BiLogoGitlab, BiLogoPaypal, BiLogoDiscord } from 'react-icons/bi';

export default class Link extends React.Component<ILinkProps, ILinkState> {
    constructor(props: ILinkProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<ILinkProps> => {
        const { id, href, title } = this.props;
        const idComponentMap: { [id: string]: React.ReactElement<ILinkProps>; } = {
            'xing': <BiLogoXing className={styles.LinkIcon} />,
            'linkedin': <BiLogoLinkedin className={styles.LinkIcon} />,
            'github': <BiLogoGithub className={styles.LinkIcon} />,
            'gitlab': <BiLogoGitlab className={styles.LinkIcon} />,
            'paypal': <BiLogoPaypal className={styles.LinkIcon} />,
            'discord': <BiLogoDiscord className={styles.LinkIcon} />
        }

        return (
            <a className={`${styles.Link}`} href={href}>
                {idComponentMap[id]}
                <div className={`${styles.LinkText}`}>{title}</div>
            </a>
        );
    }
}